<div class="data" *ngIf="!viewAgendaDoc">
    <mat-card class="back-header">
    <div class="heading-section">
    <div class="flex">
      <button mat-icon-button [disabled]="showZoomWindow" (click)="_location.back()">
        <mat-icon class="pointer">arrow_back_ios_new</mat-icon>
      </button>
      <div class="flex-self">
        <h1>Edit Draft Meeting Details</h1>
      </div>
        </div>
      <br />
        </div>
    </mat-card>
    <mat-tab-group>
        <mat-tab label="Meeting Details" class="tab-label">
            <div class="example-small-box mat-elevation-z4">
            <br />
            <mat-card class="card">
                <div class="heading-section">
                <div class="meeting-content">
                    <h2>This meeting is unpublished and not visible to meeting invitees.
                    </h2>
                    <br />
                </div>
                <div class="meeting-edit" align="left">
                    <button
                      mat-flat-button
                      color="primary"
                      (click)="publishmeeting(meetingObj)"
                    >
                      Publish
                    </button>
                </div>
                
                </div>
            </mat-card>
            <mat-card class="card">
                <div class="heading-section">
                <div class="meeting-content">
                    <h2>{{ meetingObj.meeting_title }}</h2>
                    <p>
                    <span>Start time: </span>{{ meetingObj.start_date | date: "medium" }}
                    </p>
                    <p><span> &nbsp;End time: </span> {{ meetingObj.end_date | date: "medium" }}</p>
    
                    <p><span> &nbsp; &nbsp;Address: </span>{{ meetingObj?.meeting_address }}</p>
    
                    <div class="setup-section">
                    <!-- <button mat-button routerLinkActive="router-link-active" [disabled]="!zoomObj?.id"
                        (click)="joinMeeting()">
                        <mat-icon>videocam</mat-icon> <span>Join remote</span>
                    </button> -->
                    
                      <button mat-button *ngIf="currentUser?.org_permission == 'Admin'" color="primary" [disabled]="zoomObj?.id"
                        (click)="setupRemoteMeeting(meetingObj)">
                        <strong>Setup remote meeting</strong>
                      </button>
                    </div>
                    <br />
                </div>
                <div class="meeting-edit">
                    <button mat-button [disabled]="!(currentUser?.org_permission == 'Admin')" color="primary"
                    (click)="openEditMeetingDialog(meetingObj)">
                    <span>Edit details</span>
                    </button>
                </div>
                </div>
            </mat-card>
            <br />
            <mat-card class="card">
                <div class="heading-section">
                <div class="meeting-content">
                <div class="meeting-invitees">
                <h2>Invitees</h2>
                <div class="users-profile">
                    <div class="vertical-direction" *ngFor="let item of meetingObj?.invitees">
                    <div class="image-section">
                        <img
                        class="tile-image"
                        src="../../assets/img/profile.png"
                        alt="..." 
                        class="avatar-img rounded-circle bg-lightgrey"
                        style="height: 35px; width: 35px;"
                    />
                    </div>
                    <span>{{ item?.first_name }} {{ item?.last_name }}</span>
                    </div>
                </div>
                </div>
                </div>
                <div class="meeting-edit">
                    <button mat-button [disabled]="!(currentUser?.org_permission == 'Admin')" color="primary"
                    (click)="EditInvitees(meetingObj)">
                    <span>Manage Invitees</span>
                    </button>
                </div>
                </div>
            </mat-card>
            <br />
            
            <br />
    
            <div class="example-full-width" *ngIf="items.length">
                <mat-card class="card">
                <h2>Agenda</h2>
                <mat-card-sub-title>Agenda items list </mat-card-sub-title>
                </mat-card>
    
                <mat-card class="items-body">
                <div class="items" *ngFor="let item of items; let i_item = index">
                    <div class="agenda-input">
                    <div class="item-input">
                        <div class="flex">
                        <strong matPrefix>{{ romanize(i_item + 1) }} .</strong>
                        <input matInput type="text" value="{{ item.agenda_name }}" [disabled]="true"
                            class="marging-right-15" />
                        </div>
                        <div class="item-details">
                        <p class="margin-0" *ngIf="item.presenters">
                            presenters: {{ item.presenters }}
                        </p>
                        <p class="margin-0" *ngIf="item.guests">
                            Guests: {{ item.guests }}
                        </p>
                        <p *ngIf="item.agenda_description" class="gray-color">
                          {{ item.agenda_description }}
                          </p>
                          <div *ngIf="item.agenda_document" class="row">
                            <h2><strong>uploaded files</strong></h2>
                          </div>
                          <div class="row" *ngFor="let f of item?.agendadocs" align="center">
                            <div class="col-lg-12" style="display: flex; align-items: center;">
                              <div class="col-lg-2"><button mat-icon-button *ngIf="item.agenda_document" type="button" aria-label="add"
                                (click)="openSingleAgendaDocument(f.fields)"><mat-icon >insert_drive_file</mat-icon></button></div>
                            <div class="col-lg-8" ><span style="padding-top: 3px;">{{f.fields.document_name}}</span></div>
                            <!-- <div class="col-lg-2" ><a> <mat-icon >delete</mat-icon> </a></div> -->
                            </div>
                          </div>
                        </div>   
                    </div>
                    <div class="details-btn">
                        <button mat-icon-button *ngIf="item.agenda_document" type="button" aria-label="add"
                        (click)="openAgendaDocument(item)">
                        <mat-icon class="doc-btn">description</mat-icon>
                        </button>
                        <button mat-button type="button" aria-label="add" class="edit-btn"
                        *ngIf="currentUser?.org_permission == 'Admin'" (click)="editListItemDetailsDialog(item)">
                        Edit details
                        </button>
                    </div>
                    </div>
                    <div class="level_a_items" *ngFor="let item of item?.childreen; let i_item = index">
                    <div class="agenda-input">
                        <div class="item-input">
                        <div class="flex">
                            <strong matPrefix>{{ leterize(i_item + 1) }} .</strong>
                            <input matInput type="text" value="{{ item.agenda_name }}" [disabled]="true"
                            class="marging-right-15" />
                        </div>
                        <div class="item-details">
                            <p class="margin-0" *ngIf="item.presenters">
                            presenters: {{ item.presenters }}
                            </p>
                            <p class="margin-0" *ngIf="item.guests">
                            Guests: {{ item.guests }}
                            </p>
                        </div>
                        <p *ngIf="item.agenda_description" class="gray-color">
                            {{ item.agenda_description }}
                        </p>
                        <div  class="row">
                          <h2><strong>uploaded files</strong></h2>
                        </div>
                        <div class="row" *ngFor="let f of item?.agendadocs" align="center">
                          <div class="col-lg-12" style="display: flex; align-items: center;">
                            <div class="col-lg-2"><button mat-icon-button *ngIf="item.agenda_document" type="button" aria-label="add"
                              (click)="openSingleAgendaDocument(f.fields)"><mat-icon >insert_drive_file</mat-icon></button></div>
                          <div class="col-lg-8" ><span style="padding-top: 3px;">{{f.fields.document_name}}</span></div>
                          <!-- <div class="col-lg-2" ><a> <mat-icon >delete</mat-icon> </a></div> -->
                          </div>
                        </div>
                        </div>
                        <div class="details-btn">
                        <button mat-icon-button *ngIf="item.agenda_document" type="button" aria-label="add"
                            (click)="openAgendaDocument(item)">
                            <mat-icon class="doc-btn">description</mat-icon>
                        </button>
                        <button mat-button type="button" aria-label="add" class="edit-btn"
                            *ngIf="currentUser?.org_permission == 'Admin'" (click)="editListItemDetailsDialog(item)">
                            Edit details
                        </button>
                        </div>
                    </div>
                    <div class="level_b_items" *ngFor="let item of item?.childreen; let i_item = index">
                        <div class="agenda-input">
                        <div class="item-input">
                            <div class="flex">
                            <strong matPrefix>{{ i_item + 1 }}.</strong>
                            <input matInput type="text" value="{{ item.agenda_name }}" [disabled]="true"
                                class="marging-right-15" />
                            </div>
                            <div class="item-details">
                            <p class="margin-0" *ngIf="item.presenters">
                                presenters: {{ item.presenters }}
                            </p>
                            <p class="margin-0" *ngIf="item.guests">
                                Guests: {{ item.guests }}
                            </p>
                            </div>
                            <p *ngIf="item.agenda_description" class="gray-color">
                            {{ item.agenda_description }}
                            </p>
                            <div  class="row">
                              <h2><strong>uploaded files</strong></h2>
                            </div>
                            <div class="row" *ngFor="let f of item?.agendadocs" align="center">
                              <div class="col-lg-12" style="display: flex; align-items: center;">
                                <div class="col-lg-2"><button mat-icon-button *ngIf="item.agenda_document" type="button" aria-label="add"
                                  (click)="openSingleAgendaDocument(f.fields)"><mat-icon >insert_drive_file</mat-icon></button></div>
                              <div class="col-lg-8" ><span style="padding-top: 3px;">{{f.fields.document_name}}</span></div>
                              <!-- <div class="col-lg-2" ><a> <mat-icon >delete</mat-icon> </a></div> -->
                              </div>
                            </div>
                        </div>
                        <div class="details-btn">
                            <button mat-icon-button *ngIf="item.agenda_document" type="button" aria-label="add"
                            (click)="openAgendaDocument(item)">
                            <mat-icon class="doc-btn">description</mat-icon>
                            </button>
                            <button mat-button type="button" aria-label="add" class="edit-btn"
                            *ngIf="currentUser?.org_permission == 'Admin'" (click)="editListItemDetailsDialog(item)">
                            Edit details
                            </button>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </mat-card><br />

                
            </div>


            <div class="content">
                <div class="heading-section">
                  <h2>Compose Agenda</h2>
                  <button
                    mat-flat-button
                    color="primary"
                    form="checkForm"
                    class="save"
                    [disabled]="!agendaForm.valid"
                    type="submit"
                  >
                    Save Changes
                  </button>
                </div>
                <div class="items" *ngIf="meeting !== null">
                  <div class="input-section">
                    <div class="sample-form">
                      <form
                        id="checkForm"
                        [formGroup]="agendaForm"
                        (submit)="submitAgendaItems(agendaForm.value)"
                      >
                        <div align="end">
                          <button
                            mat-button
                            color="primary"
                            class="add-item-button"
                            (click)="addItem()"
                            type="button"
                          >
                            <mat-icon>add</mat-icon>
                            Add Section
                          </button>
                          <button
                            mat-button
                            color="primary"
                            [disabled]="level == 0 || level == 3"
                            class="add-item-button"
                            (click)="addSubSection(level)"
                            type="button"
                          >
                            <mat-icon>format_list_bulleted</mat-icon>
                            Sub Section
                          </button>
                          <button
                            mat-button
                            color="warn"
                            class="add-item-button"
                            [disabled]="!level"
                            (click)="deleteSection(level)"
                            type="button"
                          >
                            <mat-icon>delete</mat-icon>
                            Delete
                          </button>
                        </div>
            
                        <mat-card class="card3">
                          <div
                            class="items-body"
                            *ngFor="
                              let parent of agendaForm.get('items')['controls'];
                              let i_parent = index
                            "
                            formArrayName="items"
                          >
                            <div class="item">
                              <div [formGroupName]="i_parent">
                                <!-- parent item -->
                                <div class="agenda-input" appearance="outline">
                                  <div class="item-input">
                                    <strong matPrefix
                                      >{{ romanize(i_parent + 1) }} .</strong
                                    >
                                    <input
                                      matInput
                                      formControlName="agenda_name"
                                      type="text"
                                      class="marging-right-15"
                                      placeholder="New section..."
                                      (focus)="setActiveItem(1, i_parent)"
                                    />
                                  </div>
                                  <div class="details-btn">
                                    <button
                                      matSuffix
                                      mat-button
                                      type="button"
                                      aria-label="add"
                                      color="primary"
                                      (click)="editItemDetailsDialog(i_parent)"
                                    >
                                      Edit details
                                    </button>
                                  </div>
                                </div>
                                <!-- leve 2 item -->
                                <div
                                  class="items-body level_a_items"
                                  *ngFor="
                                    let a_item of parent.get('level_a_items')['controls'];
                                    let i_item_a = index
                                  "
                                  formArrayName="level_a_items"
                                >
                                  <div class="item">
                                    <div [formGroupName]="i_item_a">
                                      <div class="agenda-input" appearance="outline">
                                        <div class="item-input">
                                          <strong matPrefix>{{
                                            leterize(i_item_a + 1)
                                          }}</strong>
                                          <input
                                            matInput
                                            formControlName="agenda_name"
                                            type="text"
                                            class="marging-right-15"
                                            placeholder="New section..."
                                            (focus)="setActiveItem(2, i_parent, i_item_a)"
                                          />
                                        </div>
            
                                        <div class="details-btn">
                                          <button
                                            matSuffix
                                            mat-button
                                            type="button"
                                            aria-label="add"
                                            color="primary"
                                            (click)="
                                              editItemDetailsDialog(i_parent, 1, i_item_a)
                                            "
                                          >
                                            Edit details
                                          </button>
                                        </div>
                                      </div>
            
                                      <!-- leve 3 item -->
                                      <div
                                        class="items-body level_b_items"
                                        *ngFor="
                                          let b_item of a_item.get('level_b_items')[
                                            'controls'
                                          ];
                                          let i_item_b = index
                                        "
                                        formArrayName="level_b_items"
                                      >
                                        <div class="item">
                                          <div [formGroupName]="i_item_b">
                                            <div class="agenda-input" appearance="outline">
                                              <div class="item-input">
                                                <strong matPrefix>{{
                                                  i_item_b + 1
                                                }}</strong>
                                                <input
                                                  matInput
                                                  formControlName="agenda_name"
                                                  type="text"
                                                  class="marging-right-15"
                                                  placeholder="New section..."
                                                  (focus)="
                                                    setActiveItem(
                                                      3,
                                                      i_parent,
                                                      i_item_a,
                                                      i_item_b
                                                    )
                                                  "
                                                />
                                              </div>
            
                                              <div class="details-btn">
                                                <button
                                                  matSuffix
                                                  mat-button
                                                  type="button"
                                                  aria-label="add"
                                                  color="primary"
                                                  (click)="
                                                    editItemDetailsDialog(
                                                      i_parent,
                                                      2,
                                                      i_item_a,
                                                      i_item_b
                                                    )
                                                  "
                                                >
                                                  Edit details
                                                </button>
                                              </div>
                                            </div>
            
                                            <!-- leve 4 item -->
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </mat-card>
                      </form>
                    </div>
                  </div>
                </div>
                <br />
                <br />
              </div>
    
            <br />
            </div>
        </mat-tab>
    </mat-tab-group>
</div>
<div class="view-agenda-doc" *ngIf="viewAgendaDoc">
  <br>
  <br>
  <button mat-button (click)="viewAgendaDoc = false">
    <mat-icon color="warn">close</mat-icon>Close
  </button>
  <app-view-document [pdfSrc]="agendaDoc"></app-view-document>
</div>